import React from "react";
// import HcpImage from "../assets/hcp_image.jpeg";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa6";

function Nav({
  navModalOpen,
  close,
  open,
  totalTopicsInDB,
  totalQuestionsInDB,
  navContent,
  recentQuestionsAlert,
  setRecentQuestionsAlert,
}) {
  return (
    <nav>
      <div className="personal__logo">
        <div className="personal__logo--text">
          <h4 className="personal__logo--title">{navContent[0]}</h4>
          <h1 className="personal__logo--name">{navContent[1]}</h1>
          <span className="info-text">
            <a
              className="personal__logo--link"
              href="http://www.hemenparekh.in/"
            >
              {navContent[2]}
            </a>
            {"  "}
            {navContent[3]}
            <br />
            <span className="nav-counter">{totalQuestionsInDB}</span>
            {navContent[4]}
            <span className="nav-counter">{totalTopicsInDB}</span>{" "}
            {navContent[5]}
          </span>
        </div>
        {/* <div className="personal__logo--image-container">
          <figure className="personal__logo--image-mask">
            <img src={HcpImage} className="personal__logo--image" alt="HCP" />
          </figure>
        </div> */}
      </div>
      <ul className="nav__link--list">
        <li className="nav__link">
          <Link to="/" className="nav__link--anchor">
            <p className="nav__link--text">{navContent[6]}</p>
          </Link>
        </li>
        <li className="nav__link">
          <Link to="/faqs" className="nav__link--anchor">
            <p className="nav__link--text">{navContent[7]}</p>
          </Link>
        </li>
        {/* <li className="nav__link">
          <Link
            to="/recently-asked-questions"
            className="nav__link--anchor"
            onClick={() => {
              setRecentQuestionsAlert("nav__link--text");
            }}
          >
            <p className={recentQuestionsAlert}>{navContent[8]}</p>
          </Link>
        </li> */}
        <li className="nav__link">
          <Link to="/stats" className="nav__link--anchor">
            <p className="nav__link--text">{navContent[9]}</p>
          </Link>
        </li>
        <li className="nav__link">
          <a
            href="http://www.hemenparekh.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav__link--anchor"
          >
            <p className="nav__link--text">{navContent[10]}</p>
          </a>
        </li>
        <li className="nav__link">
          <Link
            to="/create-your-own-digital-avatar"
            className="nav__link--anchor"
          >
            <p className="nav__link--text">{navContent[11]}</p>
          </Link>
        </li>
        <li className="hamburger-menu">
          <FaBars
            onClick={() => {
              navModalOpen ? close() : open();
            }}
          />
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
